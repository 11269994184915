<template>
    <div>
        <v-card>
            <v-card-title class="px-2">
                <router-link class="font-weight-medium text-decoration-none me-2" :to="{ name: 'manager-store' }">
                    <v-tooltip color="#212121" top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn color="primary" v-bind="attrs" icon v-on="on">
                                <v-icon>{{ mdiArrowLeftBold }}</v-icon>
                            </v-btn>
                        </template>
                        <span>ย้อนกลับ</span>
                    </v-tooltip>
                </router-link>
                <span>
          ({{ dataStore.store_code }}){{ dataStore.store_name }} <StatusBlock :status="String(dataStore.store_active)"/>
        </span>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="isAdd = true">เพิ่มสินค้าเข้าคลัง</v-btn>
            </v-card-title>
            <div class="mx-2 d-sm-flex">
                <span class="font-weight-semibold pe-2 text-decoration-underline">มูลค่ารวมคลังสินค้า : </span> <span
                    class="text-decoration-underline">{{ dataStore.store_price_total }}</span>
                <v-spacer></v-spacer>
                <span class="font-weight-semibold pe-2 text-decoration-underline">รวมราคาทุน : </span> <span
                    class="text-decoration-underline">{{ dataStore.store_cost_total }}</span>
                <v-spacer></v-spacer>
                <span class="font-weight-semibold pe-2 text-decoration-underline">รวมราคาขาย : </span> <span
                    class="text-decoration-underline">{{ dataStore.store_price_sum }}</span>
                <v-spacer></v-spacer>
                <span class="font-weight-semibold pe-2 text-decoration-underline">จำนวนทั้งหมด : </span> <span
                    class="text-decoration-underline">{{ dataStore.store_balance_total }}</span>
            </div>
            <v-divider></v-divider>
            <v-data-table
                    :headers='headers'
                    :items='dataList'
                    :items-per-page='itemsPerPage'
                    :loading='loading'
                    no-data-text='ไม่มีข้อมูล !'
                    loading-text='กำลังโหลดข้อมูล...'
                    :footer-props='footer'
                    no-results-text='ไม่มีข้อมูล !'
                    class='elevation-1'
            >
                <template #[`item.store_product_id`]='{ index }'>
                    {{ index + 1 }}
                </template>
                <template #[`item.store_product_cost`]='{ item }'>
          <span>
            {{ item.store_product_cost }}
          <CurrentCurrency/>
          </span>
                </template>
                <template #[`item.store_product_price`]='{ item }'>
          <span>
            {{ item.store_product_price }}
          <CurrentCurrency/>
          </span>
                </template>
                <template #[`item.store_product_total`]='{ item }'>
          <span>
            {{ item.store_product_total }}
          <CurrentCurrency/>
          </span>
                </template>
                <template #[`item.actions`]='{ item }'>
                    <v-tooltip top>
                        <template v-slot:activator='{ on, attrs }'>
                            <v-btn
                                    color='primary'
                                    icon
                                    v-bind='attrs'
                                    @click='dataEdit = item;isEdit = true'
                                    v-on='on'
                            >
                                <v-icon>{{ mdiPencilOutline }}</v-icon>
                            </v-btn>
                        </template>
                        <span>แก้ไข</span>
                    </v-tooltip>
                    <v-tooltip top>
                        <template v-slot:activator='{ on, attrs }'>
                            <v-btn
                                    color='primary'
                                    icon
                                    v-bind='attrs'
                                    @click='dataEdit = item;isUpdateStatus = true'
                                    v-on='on'
                            >
                                <v-icon>{{ mdiDeleteOutline }}</v-icon>
                            </v-btn>
                        </template>
                        <span>ลบ</span>
                    </v-tooltip>
                </template>
            </v-data-table>
        </v-card>
        <AddNewProductToStore v-model="isAdd" :product-list="productList" @onAdd="getStoreDetail();getProductList()"/>
        <EditProductToStore v-model="isEdit" :data-edit="dataEdit" :product-list="productList"
                            @onUpdate="getStoreDetail();getProductList()"/>
        <SuspendProductInStore v-model="isUpdateStatus" :data-update="dataEdit"
                               @onUpdate="getStoreDetail();getProductList()"/>
    </div>
</template>

<script>
import { ref, watch, onMounted } from "@vue/composition-api";
import { api } from "@/services/api"
import footerDataTables from "@/fake-db/footerDataTables.json"
import StatusBlock from "@/components/StatusBlock.vue"
import { mdiArrowLeftBold, mdiPencilOutline, mdiDeleteOutline, mdiCheck } from "@mdi/js";
import AddNewProductToStore from "@/views/manager/store/AddNewProductToStore";
import EditProductToStore from "@/views/manager/store/EditProductToStore";
import SuspendProductInStore from "@/views/manager/store/SuspendProductInStore";
import CurrentCurrency from "@/components/CurrentCurrency";
import store from "@/store"

export default {
    components: { EditProductToStore, AddNewProductToStore, StatusBlock, SuspendProductInStore, CurrentCurrency },
    setup(_, { root, emit }) {
        const dataStore = ref({})
        const productList = ref([])
        const headers = ref([
            {
                text: '#',
                value: 'store_product_id',
                width: '50',
            },
            {
                text: 'รหัสสินค้า',
                value: 'product_code',
                width: '120',
            },
            {
                text: 'ขื่อสินค้า',
                value: 'product_name',
                width: '200',
            },
            {
                text: 'น้ำหนัก',
                value: 'product_weight',
                align: 'end',
                width: '100',
            },
            {
                text: 'หน่วย',
                value: 'product_unit',
                width: '100',
            },
            {
                text: 'ราคาทุน',
                value: 'store_product_cost',
                width: '150',
                align: 'end',
            },
            {
                text: 'ราคาขาย',
                value: 'store_product_price',
                width: '150',
                align: 'end',
            },
            {
                text: 'จำนวน/ชิ้น',
                value: 'store_product_balance',
                width: '150',
                align: 'end',
            },
            {
                text: 'ราคารวมขาย/ชิ้น',
                value: 'store_product_total',
                width: '180',
                align: 'end',
            },
            {
                text: 'ตัวเลือก',
                value: 'actions',
                width: '250',
                align: 'center'
            },
        ])
        const dataList = ref([])
        const itemsPerPage = ref(10)
        const loading = ref(false)
        const footer = ref(footerDataTables)
        const isAdd = ref(false)
        const isEdit = ref(false)
        const isUpdateStatus = ref(false)
        const dataEdit = ref({})

        onMounted(() => {
            getStoreDetail()
            getProductList()
        })

        const getProductList = () => {
            api.get({
                path: '/manager/products?product_active=1',

            }).then(res => {
                productList.value = res.data
            }).catch(err => {
                console.log('err : ', err)
            })
        }

        const getStoreDetail = () => {

            api.get({
                path: '/manager/store',
                param: `${ root.$route.params.id }?currency_id=${ localStorage.getItem('currencyId') }`
            }).then(({ data, store }) => {
                dataStore.value = store
                dataList.value = data
            }).catch(err => {
                console.log('err : ', err)
            })

        }


        return {
            dataStore,
            productList,
            headers,
            isEdit,
            isAdd,
            dataList,
            mdiArrowLeftBold,
            itemsPerPage,
            isUpdateStatus,
            dataEdit,
            getProductList,
            getStoreDetail,
            mdiPencilOutline, mdiDeleteOutline, mdiCheck,
            loading,
            footer,
        }
    },

}
</script>

<style scoped>

</style>
