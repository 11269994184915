<template>
  <div>
    <v-dialog v-model='isAddNewProductToStore' persistent max-width='500'>
      <v-card>
        <v-card-title>
          เพิ่มสินค้าในคลังสินค้า
        </v-card-title>
        <v-divider></v-divider>
        <v-form ref='formAddNewStore' @submit.prevent='createStore'>
          <v-row class='mx-2 my-2'>
            <v-col cols='12'>
              <v-autocomplete
                  :items="productList"
                  v-model='productSelection'
                  filled
                  dense
                  label='รายการสินค้า'
                  placeholder='รายการสินค้า'
                  hide-details='auto'
                  item-value="product_id"
                  item-text="product_name"
                  @change="section"
                  return-object
                  :rules='[required]'
              >
                <template slot="item" slot-scope="{item}">
                  <v-list-item-content>
                    <v-list-item-title>
                      ({{ item.product_code }}) {{ item.product_name }} {{ item.product_weight }} {{
                        item.product_unit
                      }}
                    </v-list-item-title>
                  </v-list-item-content>
                </template>
                <template slot="selection" slot-scope="{item}">
                  <v-list-item-content>
                    <v-list-item-title>
                      ({{ item.product_code }}) {{ item.product_name }}
                    </v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols='12' class="d-flex">
              <v-text-field v-model='product_weight' filled
                            dense
                            label='น้ำหนัก'
                            placeholder='น้ำหนัก'
                            readonly
                            class="me-2"
                            hide-details
              ></v-text-field>
              <v-text-field v-model='product_unit' filled
                            dense
                            label='หน่วย'
                            placeholder='หน่วย'
                            readonly
                            hide-details
              ></v-text-field>
            </v-col>
            <v-col cols='12' class="d-flex">
              <v-text-field v-model='product_cost' filled
                            dense
                            label='ราคาทุน'
                            placeholder='ราคาทุน'
                            class="me-2"
                            type="number"
                            hide-details="auto"
                            :rules='[required]'
              ></v-text-field>
              <v-text-field v-model='product_price' filled
                            dense
                            label='ราคาขาย'
                            type="number"
                            placeholder='ราคาขาย'
                            hide-details="auto"
                            :rules='[required]'
              ></v-text-field>
            </v-col>
            <v-col cols='12' class="d-flex align-end ">
              <v-text-field v-model='product_amount' filled
                            dense
                            label='จำนวน/ชิ้น'
                            placeholder='จำนวน/ชิ้น'
                            style="max-width: 45%"
                            class="me-2"
                            hide-details="auto"
                            :rules='[required]'
              ></v-text-field>
              <v-spacer></v-spacer>
              <div class="font-weight-semibold text-right primary--text text-decoration-underline">
                ราคารวม : {{ product_cost * product_amount }}
              </div>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color='primary' type='submit' :loading='loading'
                   :disabled='loading'>
              บันทึก
            </v-btn>
            <v-btn color='secondary' outlined
                   @click='$emit(`update:isAddNewProductToStore`,false)'>
              ยกเลิก
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { ref, watch } from "@vue/composition-api";
import { required } from "@/@core/utils/validation";
import { api } from "@/services/api";
import store from "@/store"

export default {
  model: {
    prop: 'isAddNewProductToStore',
    event: 'update:isAddNewProductToStore',
  },
  props: {
    isAddNewProductToStore: {
      type: Boolean,
      default: false,
    },
    productList: {
      type: Array,
      default: () => [],
    },
  },
  components: {},
  setup(props, { root, emit }) {
    const productSelection = ref(null)
    const product_cost = ref(0)
    const product_price = ref(0)
    const product_amount = ref(0)
    const product_weight = ref(0)
    const product_unit = ref('')
    const loading = ref(false);
    const formAddNewStore = ref(null)


    const createStore = () => {
      const isFormValid = formAddNewStore.value.validate()
      if (!isFormValid) return
      api.post({
        path: '/manager/storeProduct',
        body: {
          store_id: root.$route.params.id,
          currency_id: store.state.app.currentCurrencyId,
          product_id: productSelection.value.product_id,
          store_product_cost: product_cost.value,
          store_product_price: product_price.value,
          store_product_balance: product_amount.value,
        },
      }).then(res => {
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
        if (res.response) {
          emit('update:isAddNewProductToStore', false)
          emit('onAdd')
          loading.value = false
        } else {
          loading.value = false
        }
      }).catch(err => {
        console.log('error :', err)
      })
    }
    const section = () => {
      product_weight.value = productSelection.value.product_weight
      product_unit.value = productSelection.value.product_unit
      product_cost.value = 0
      product_price.value = 0
      product_amount.value = 0
    }
    const clearData = () => {
      productSelection.value = null
      product_cost.value = 0
      product_price.value = 0
      product_amount.value = 0
      product_weight.value = 0
      product_unit.value = ''
    }
    watch(() => props.isAddNewProductToStore, value => {
      if (value) {
        clearData()
      }
    })
    return {
      loading,
      createStore,
      productSelection,
      required,
      section,
      product_cost,
      product_price,
      product_amount,
      product_weight,
      product_unit,
      formAddNewStore
    }
  },

}
</script>

<style scoped>

</style>
